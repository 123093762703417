import React from 'react'

import * as S from './styled'

function CPCardItem(item) {
  const doesImageExist = item.src

  return (
    <S.CardItemWrapper className={item.className}>
      <S.CardItemImageWrapper loading={item.loading} className="grid-image-wrapper">
        {item.loading ? null : (
          <S.CardItemImage
            doesImageExist={doesImageExist}
            src={
              item.src ? item.src : item.image ? item.image : '/static/images/logo/cp_logo_gray.svg'
            }
          />
        )}
      </S.CardItemImageWrapper>
      <S.TextWrapper>
        <S.CardItemTitle loading={item.loading}>{item.title}</S.CardItemTitle>
        <S.CardItemSubtitle loading={item.loading}>{item.subtitle}</S.CardItemSubtitle>
      </S.TextWrapper>
    </S.CardItemWrapper>
  )
}

export default CPCardItem
