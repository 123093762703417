import styled from 'styled-components'

import CPGridWithPagination from 'components/common/CPGridWithPagination'
import {ButtonPrimary} from 'components/common/buttons'

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-bottom: 2px solid ${props => props.theme.colors.secondary1};
  padding: 24px 0px 48px 0px;
  :last-child {
    border-bottom: none;
  }

  ${props => props.theme.mediaQuery.md} {
    padding: 24px 0px 64px 0px;
  }
`

export const SectionHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Icon = styled.div`
  width: 48px;
  height: 48px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
`

export const Title = styled.div`
  font-size: 22px;
  color: black;
  letter-spacing: -0.44px;
`

export const Subtitle = styled.div`
  color: ${props => props.theme.colors.secondary5};
  font-size: 14px;
  text-transform: lowercase;
`

export const SeeAllButton = styled(ButtonPrimary)`
  display: flex;
  padding: 19px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid ${props => props.theme.colors.secondary2};
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  height: auto;

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    color: ${props => props.theme.colors.secondary5};
  }
  > :not(:last-child) {
    margin-right: 0px;
  }
  :hover {
    color: ${props => props.theme.colors.secondary7};
    border: 1px solid ${props => props.theme.colors.secondary4};
    background: white;
  }
`

export const ResultGrid = styled(CPGridWithPagination)`
  > div:first-child {
    width: 95vw;
    margin-left: -24px;
    padding-left: 24px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    overflow-x: auto;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    ${props => props.theme.mediaQuery.md} {
      width: 100%;
      grid-template-columns: ${props =>
        props.item === 'experiences' ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)'};
    }

    .grid-image-wrapper {
      width: 287px;
      height: ${props =>
        props.item === 'attractions' ? '212px' : props.item === 'guides' ? '287px' : '206px'};

      ${props => props.theme.mediaQuery.md} {
        width: 100%;
        height: ${props =>
          props.item === 'attractions' ? '200px' : props.item === 'guides' ? '270px' : '212px'};
      }
    }

    .grid-item-wrapper {
      flex-direction: column;
      gap: 16px;

      ${props => props.theme.mediaQuery.md} {
        gap: 0;
      }
      > div {
        padding-left: 0;
      }
    }
  }
`
